<template>
  <div class="contact container">
    <div class="contact__content">
      <div class="contact__content__left">
        <h1>Vragen of <br/> opmerkingen?</h1>
        <p>Stuur mij gerust een berichtje. Ik probeer zo snel mogelijk antwoord te geven, dit kan door drukte een paar dagen duren.</p>
      </div>
      <div class="contact__content__right">
        <div class="contact__content__phone">
          <i class="fa fa-phone"></i>
          <h3>Telefoon</h3>
          <p><img src="@/assets/txt1-img.png" width="140" /></p>
        </div>

        <div class="contact__content__email">
          <i class="fa fa-envelope"></i>
          <h3>Email</h3>
          <p><img src="@/assets/txt-img.png" width="130" /></p>
        </div>
      </div>
    </div>

    <Form v-if="!state.isSubmitted" id="contact" class="contact__form" :validation-schema="schema" @submit="onSubmit">
      <div v-if="state.recaptcha.length" class="contact__form__messages">{{ state.recaptcha }}</div>

      <Input
        name="field" 
        type="text" 
        label="field" 
        placeholder="Field" 
        success-message="" 
        style="display: none;" 
      />

      <div class="contact__form__fields">
        <Input
          name="name"
          type="text"
          label="Naam"
          placeholder="Naam"
          success-message="Aangenaam!"
        />

        <Input
          name="email"
          type="email"
          label="Email"
          placeholder="Email"
          success-message="Ziet er goed uit! Geen typefouten?"
        />

        <Input
          name="phone"
          type="text"
          label="Telefoon"
          placeholder="Telefoon"
          success-message=""
        />       

        <Textarea 
          name="message"
          label="Bericht"
          placeholder="Bericht"
          success-message=""
        ></Textarea>   

        <div class="form__field __captcha">
          <div class="g-recaptcha contact__form__recaptcha " data-sitekey="6LeXAQMgAAAAACYIPD_E5RI4-5y1QC8047kbXXS6" data-theme="light"></div>
        </div>

        <button class="contact__form__button" type="submit">Versturen</button>                       
      </div>
    </form>

    <div v-else>
      <p>Bedankt voor je bericht!</p>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { setLocale } from 'yup';
import emailjs from 'emailjs-com';

import Input from "@/components/forms/Input.vue";
import Textarea from "@/components/forms/Textarea.vue";

import './contact.scss'

export default {
  name: "contact",
  components: {
    Input,
    Textarea,
    Form,
  },
  setup() {
    //Test keys
    //Recaptcha key 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
    //Recaptcha secret 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe

    const state = reactive({
      isSubmitted: false,
      recaptcha: ''
    })

    function onSubmit() {
      emailjs.sendForm('service_fotochick', 'template_fotochick', 'contact', 'user_D24C9s8kFVIsIOpOHhZKu').then(() => {
          state.isSubmitted = true;
        }, () => {
          state.recaptcha = 'Vink nog even aan dat je geen robot bent!';
      })
    }

    setLocale({
      mixed: {
        required: 'Dit veld is verplicht',
      },
      string: {
        email: 'Vul een correct emailadres in',
        min: 'Gebruik minimaal ${min} tekens',
      }
    });

    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    const schema = Yup.object().shape({
      field: Yup.string().max(0, 'Laat dit veld leeg'),
      name: Yup.string().min(5).matches('^[ a-z/A-Z-]*$', 'Gebruik alleen letters en spaties').required(),
      email: Yup.string().email().required(),
      phone: Yup.string().test('min10', 'Een telefoonnummer bestaat uit 10 cijfers', value => (value.trim().length > 0 ? value.length >= 10 : true)).matches('^[ 0-9-+]*$', 'Gebruik alleen cijfers, evt met +, - en spaties'),
      message: Yup.string().required(),
    });

    return {
      onSubmit,
      schema,
      state
    };
  }
}
</script>